import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import styles from "./globals.css?url";
import { LinksFunction } from "@remix-run/node";
import { withSentry, captureRemixErrorBoundaryError } from "@sentry/remix";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Page } from "./layout";
import { LoginForm } from "./login";
import { useTarget } from "./targetPolyfill";
import { errors as openidErrors } from "openid-client";

export const links: LinksFunction = () => {
  return [
    {
      rel: "icon",
      href: "/favicon.jpg",
      type: "image/jpg",
    },
    {
      rel: "stylesheet",
      href: styles,
    },
  ];
};

export function Layout({ children }: { children: React.ReactNode }) {
  useTarget();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return (
    <Page user={null}>
      <main className="container mx-auto">
        <div className="max-w-prose mx-auto text-content">
          <Error error={error} />
        </div>
      </main>
    </Page>
  );
}

function Error({ error }: { error: unknown }) {
  if (error instanceof openidErrors.OPError) {
    return (
      <ErrorWrapper
        title="Sign in Error"
        message={<p>Something when wrong signing you in.</p>}
      />
    );
  } else if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 401:
        return (
          <ErrorWrapper
            title="Unauthenticated"
            message={
              <>
                <p>Try logging in?</p>
                <LoginForm />
              </>
            }
          />
        );
      case 403:
        return (
          <ErrorWrapper
            title="Unauthorized"
            message={<p>You&apos;re not allowed to do that!</p>}
          />
        );
      case 404:
        return (
          <ErrorWrapper
            title="Page not found"
            message={<p>It looks like you&apos;ve taken a wrong turn!</p>}
          />
        );
    }
  }

  if (error instanceof Error) {
    return <ErrorWrapper title="Something went wrong" message={null} />;
  }

  return <h1>Unknown Error</h1>;
}

function ErrorWrapper({
  title,
  message,
}: {
  title: string;
  message: React.ReactNode;
}) {
  return (
    <div className="text-content">
      <h2>{title}</h2>
      {message}
    </div>
  );
}

export default withSentry(App);
