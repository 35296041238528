import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

export function useTarget() {
  const location = useLocation();

  useEffect(() => {
    // https://github.com/remix-run/react-router/issues/11220
    // simulate :target
    document
      .querySelectorAll("[data-target]")
      .forEach((el) => el.removeAttribute("data-target"));
    const element = document.getElementById(location.hash.slice(1));
    if (element) {
      element.dataset.target = "true";
    }
    window.location.hash = location.hash;
  }, [location.hash]);
}
